.h5 {
  color: #5f6b7c;
}

.vetting-container {
  display: grid;
  grid-template:
    "Header Target" auto
    "PDF Sidebar" 1fr / 60% 40%;
}

.vetting-header {
  grid-area: Header;
}

.vetting-pdf {
  grid-area: PDF;
}

.vetting-sidebar {
  grid-area: Sidebar;
}

.vetting-context-header {
  font-weight: bold;
  font-size: 2em;
  float: right;
  grid-area: Target;
}

.uncertain-decimal .error {
  color: #808080;
}

.tev-symbol {
  vertical-align: middle;
}

.uncertain-decimal .tev-symbol {
  padding-left: 0.5em;
  padding-right: 0.1em;
  color: #808080;
}

.tfop {
  display: flex;
  flex-direction: row;
}

.tfop .subgroup {
  padding: 0.5em;
}

.scrollbar-tab {
  max-height: 80vh;
  overflow-y: auto;
}
